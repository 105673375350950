/* Header Section */
.shopify-header {
    background-size: cover;
    background-position: center;
    text-align: center;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.shopify-header h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.shopify-header p {
    font-size: 1.2rem;
    font-weight: 300;
}

/* Sections */
.shopify-section {
    background-color: white;
    padding: 50px;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Headings */
.shopify-section h2 {
    color: #2c3e50;
    font-size: 2rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    border-bottom: 3px solid #3498db;
    display: inline-block;
    padding-bottom: 5px;
}

/* Lists */
ul.bullet-points {
    list-style-type: none;
    padding: 0;
}

ul.bullet-points li {
    font-size: 1.1rem;
    margin: 20px 0;
    padding-left: 20px;
    position: relative;
}

ul.bullet-points li::before {
    content: "✔";
    color: #27ae60;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 0;
}

/* Images */
.shopify-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
}

.image:hover {
    transform: scale(1.05);
}

/* Blockquotes */
blockquote {
    font-style: italic;
    font-size: 1.2rem;
    background: #ecf0f1;
    padding: 20px;
    border-left: 5px solid #3498db;
    margin: 20px 0;
    border-radius: 5px;
}