@keyframes staffBoxAnimation {
    from {
        height:40px;
    }

    to {
        height: 90%;                
    }
}
.staff-box {
    background-color: white;
    color: black;
    padding-top: 20px;
    padding-bottom: 20px;
}
.staff-box:hover {
    animation: staffBoxAnimation 0.3s ease-out;    
    background: linear-gradient(180deg, #4A8FAF 0%, #4A8FAF 0.01%, rgba(74, 143, 175, 0.16) 100%);    
    height: 90%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;        
}
.staff-box:hover .hoveredTypography {
    color: white;
}